const users = [
  {
    username: "Pdax-api",
    password: "123456",
  },
  {
    username: "Pdax-api",
    password: "mkjenfoiawoo6^$WBKinxe",
  },
];

export default users;
